import React, { useEffect, useState } from 'react'
import { IonHeader, IonPage, IonIcon } from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import { post } from '../utilities/API'
import StateDef from '../reducers/state.d'
import { useTranslation, Trans } from 'react-i18next'
import './DemoCommon.scss'
import { RouteComponentProps } from 'react-router'
import { fetchSettings } from '../reducers/settingsSlice'
import { save } from '../reducers/demoAccountSlice'
import { set } from '../reducers/pullpathAlertSlice'
import Regex from '../utilities/Regex'
import { language } from 'ionicons/icons'
import { set as setLan } from '../reducers/languagePickerSlice'

const Email: React.FC<RouteComponentProps> = ({ history, match }) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: StateDef) => state.settings)
    const demoSignInInfo = useSelector((state: StateDef) => state.demoSignIn)
    let [email, setEmail] = useState<string>('')
    const { t } = useTranslation()
    function continueWithEmail() {
        if (!Regex.email.test(email)) {
            dispatch(set({
                showAlert: true,
                header: t('Invalid email address'),
                message: t('Your email does not appear to a valid address. \r\nPlease check your typing, spelling and usage of @ symbols to make sure it is correct.')
            }))
            return
        }
        post('/demo/emailcheck', { email: email }).then(res => {
            let isExisted = res.payload.status === 'invalid'
            if (isExisted) {
                post('/demo/signin', { email: email }).then(res => {
                    // account not registered
                    if (res.payload.status === 'invalid') {
                        // todo
                    }
                    // account registered, email has been sent
                    else if (res.payload.status === 'ok') {
                        dispatch(set({
                            showAlert: true,
                            header: t('Demo account exists'),
                            message: t('It looks like you have already registered a demo account using <strong>##</strong>.\r\n\r\nWe have sent you an email with a link to login.').replace(/##/, email),
                        }))
                    }
                })
            }
            else {
                dispatch(save({
                    email: email
                }))
                setEmail('')
                history.push('/signup/password')
            }
        })
    }

    useEffect(() => {
        if (!settings.themeColor) {
            dispatch(fetchSettings())
        }
        if (demoSignInInfo.email) {
            setEmail(demoSignInInfo.email)
        }
    }, [demoSignInInfo.email, settings.themeColor, dispatch])

    return (
        <IonPage className="welcome">
            <IonHeader>
                <div className="banner" style={{ backgroundColor: settings.themeColor }}>
                    <IonIcon icon={language} onClick={() => {
                        dispatch(
                            setLan({
                                isOpen: true
                            })
                        )
                    }} />
                </div>
            </IonHeader>
            <div className="content">
                <div className="more-options tr-button">
                    <span style={{ color: settings.themeColor, display: 'none' }} onClick={() => { 
                        setEmail('')
                        history.push('/signin/demo')
                     }}>
                        <Trans>
                            Sign in
                        </Trans>
                    </span>
                </div>
                <div className="logo">
                    <img src="https://image.cdn.pullpath.com.cn/pp-logo-sq-border.jpg" alt="" />
                </div>
                <div style={{ color: settings.themeColor }} className="text">
                    <Trans>
                        What's your work email address?
                    </Trans>
                </div>
                <div className="inputField">
                    <input value={email} placeholder={t("your email address")} onKeyUp={(evt) => {
                        evt.key === 'Enter' && continueWithEmail()
                    }} onChange={(evt) => {
                        setEmail(evt.target.value)
                    }}></input>
                </div>
                <div className="button-section">
                    <button style={{ backgroundColor: settings.themeColor, borderColor: settings.themeColor, color: 'white' }} onClick={continueWithEmail}>
                        <Trans>
                            Continue
                        </Trans>
                    </button>
                </div>
                <div className="text note disabled">
                    <Trans>
                        We ask for your work email address so that your colleagues will recognize it when you invite them. We will send you a confirmation email to this address.
                    </Trans>
                </div>
            </div>
        </IonPage>
    )
}

export default Email
