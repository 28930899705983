import React, { useEffect, useState } from 'react'
import { IonHeader, IonPage, IonIcon } from '@ionic/react'
import { useTranslation, Trans } from 'react-i18next'
import './DemoCommon.scss'
import { RouteComponentProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import StateDef from '../reducers/state.d'
import { fetchSettings } from '../reducers/settingsSlice'
import { save } from '../reducers/demoAccountSlice'
import { set } from '../reducers/pullpathAlertSlice'
import { language } from 'ionicons/icons'
import { set as setLan } from '../reducers/languagePickerSlice'

const Password: React.FC<RouteComponentProps> = ({ history, match }) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: StateDef) => state.settings)
    let [password, setPassword] = useState<string>('')
    const { t } = useTranslation()
    function continueWithPassword() {
        if (!password) {
            dispatch(set({
                showAlert: true,
                header: t('Invalid password'),
                message: t('Password should not be empty')
            }))
            return
        }
        dispatch(save({
            password: password
        }))
        history.push('/signup/demourl')
        setPassword('')
    }

    useEffect(() => {
        if (!settings.themeColor) {
            dispatch(fetchSettings())
        }
    }, [settings.themeColor, dispatch])

    return (
        <IonPage className="welcome">
            <IonHeader>
                <div className="banner" style={{ backgroundColor: settings.themeColor }}>
                    <IonIcon icon={language} onClick={() => {
                        dispatch(
                            setLan({
                                isOpen: true
                            })
                        )
                    }} />
                </div>
            </IonHeader>
            <div className="content">
                <div className="more-options tr-button">
                    <span style={{ color: settings.themeColor, display: 'none' }} onClick={() => { 
                        setPassword('')
                        history.push('/signin/demo')
                     }}>
                        <Trans>
                            Sign in
                        </Trans>
                    </span>
                </div>
                <div className="logo">
                    <img src="https://image.cdn.pullpath.com.cn/pp-logo-sq-border.jpg" alt="" />
                </div>
                <div style={{ color: settings.themeColor }} className="text">
                    <Trans>
                        Please create a password.
                    </Trans>
                </div>
                <div className="inputField">
                    <input type="password" value={password} placeholder={t("Your new password")} onKeyUp={(evt) => {
                        evt.key === 'Enter' && continueWithPassword()
                    }} onChange={(evt) => {
                        setPassword(evt.target.value)
                    }}></input>
                </div>
                <div className="button-section">
                    <button style={{ backgroundColor: settings.themeColor, borderColor: settings.themeColor, color: 'white' }} onClick={continueWithPassword}>
                        <Trans>
                            Continue
                        </Trans>
                    </button>
                </div>
                <div className="text note disabled">
                    <Trans>
                        We ask for your work email address so that your colleagues will recognize it when you invite them. We will send you a confirmation email to this address.
                    </Trans>
                </div>
            </div>
        </IonPage>
    )
}

export default Password
