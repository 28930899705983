import React, { useEffect } from 'react'
import { IonHeader, IonIcon, IonPage } from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { language } from 'ionicons/icons'
import './DemoCommon.scss'
import { RouteComponentProps } from 'react-router'
import { fetchSettings } from '../reducers/settingsSlice'
import StateDef from '../reducers/state.d'
import { set as setLan } from '../reducers/languagePickerSlice'

const Welcome: React.FC<RouteComponentProps> = ({ history }) => {
    const settings = useSelector((state: StateDef) => state.settings)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchSettings())
    }, [dispatch])
    function createDemoSite() {
        history.push('/signup/email')
    }
    return (
        <IonPage className="welcome">
            <IonHeader>
                <div className="banner" style={{ backgroundColor: settings.themeColor }}>
                    <IonIcon icon={language} onClick={() => {
                        dispatch(
                            setLan({
                                isOpen: true
                            })
                        )
                    }} />
                </div>
            </IonHeader>
            <div className="content">
                <div className="more-options tr-button">
                    <span style={{ color: settings.themeColor, display: 'none' }} onClick={() => { history.push('/signin/demo') }}>
                        <Trans>
                            Sign in
                        </Trans>
                    </span>
                </div>
                <div className="logo">
                    <img src="https://image.cdn.pullpath.com.cn/pp-logo-sq-border.jpg" alt="" />
                </div>
                <div style={{ color: settings.themeColor }} className="text">
                    <Trans>
                        Let's get started.
                    </Trans>
                </div>
                <div className="button-section">
                    <button style={{ color: settings.themeColor, borderColor: settings.themeColor }} onClick={createDemoSite}>
                        <Trans>
                            Create Site
                        </Trans>
                    </button>
                </div>
                <div style={{ color: settings.themeColor }} className="text note">
                    <Trans>
                        We will create a new demo site so that you can try using Pullpath with your team. The demo site is free for small teams, up to 20 people.
                    </Trans>
                </div>
                <div className="text note disabled">
                    <Trans>
                        By clicking Create Site, Continue, Create Account or More Options above, I agree to the Terms of this site. By going to the next step, I confirm that I am 18 years old or above.
                    </Trans>
                </div>
            </div>
        </IonPage>
    )
}

export default Welcome
