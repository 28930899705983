import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'demoSignIn',
    initialState: {
        email: '',
    },
    reducers: {
        save: (state, action) => {
            return {...state, ...action.payload}
        },
        get: (state, action) => {
            return {...state}
        }
    }
})

export default slice.reducer
export const { save, get } = slice.actions