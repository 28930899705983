import settings from './settingsSlice'
import loading from './loadingSlice'
import demoAccount from './demoAccountSlice'
import pullpathAlert from './pullpathAlertSlice'
import pullpathError from './pullpathErrorSlice'
import languagePicker from './languagePickerSlice'
import demoSignIn from './demoSignInSlice'
import { combineReducers } from 'redux'

export default combineReducers({
    settings,
    loading,
    demoAccount,
    pullpathAlert,
    pullpathError,
    languagePicker,
    demoSignIn
})