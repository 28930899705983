import React, { useEffect, useState } from 'react'
import { IonHeader, IonPage, IonIcon } from '@ionic/react'
import { post } from '../utilities/API'
import { useTranslation, Trans } from 'react-i18next'
import './DemoCommon.scss'
import { RouteComponentProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import StateDef from '../reducers/state.d'
import { fetchSettings } from '../reducers/settingsSlice'
import { save } from '../reducers/demoAccountSlice'
import { set } from '../reducers/pullpathAlertSlice'
import Regex from '../utilities/Regex'
import { language } from 'ionicons/icons'
import { set as setLan } from '../reducers/languagePickerSlice'

const DemoURL: React.FC<RouteComponentProps> = ({ history, match }) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: StateDef) => state.settings)
    const demoAccountInfo = useSelector((state: StateDef) => state.demoAccount)
    let [demoURL, setDemoURL] = useState<string>('')
    const { t } = useTranslation()
    function continueWithDemoURL() {
        if (!demoURL) {
            dispatch(set({
                showAlert: true,
                header: t('Please choose URL'),
                message: t('To create your demo site, please type in your company or brand name in the box on the middle of the page before clicking the Continue button.')
            }))
            return
        }
        if (!Regex.demoUrl.test(demoURL)) {
            dispatch(set({
                showAlert: true,
                header: t('Please update site'),
                message: t('Your demo site address can only contain letters and numbers 0-9, no other special characters or spaces. Please change it and try again.')
            }))
            return
        }
        dispatch(save({
            name: demoURL
        }))
        post('/demo/register', { ...demoAccountInfo, name: demoURL }).then(res => {
            if (res.payload?.status === 'invalid') {
                dispatch(set({
                    showAlert: true,
                    header: t('URL already in use'),
                    message: t('We\'ve already created a demo at that URL.\r\n\r\nPlease choose a different URL.')
                }))
            }
            else if (res.payload?.code === 'OK') {
                dispatch(set({
                    showAlert: true,
                    header: t('Awaiting confirmation'),
                    message: t('We\'ve sent you a confirmation email to ##. \r\n\r\nClick the link in the email to continue to the demo site.').replace(/##/, demoAccountInfo.email)
                }))
            }
        })
    }

    useEffect(() => {
        if (!settings.themeColor) {
            dispatch(fetchSettings())
        }
    }, [settings.themeColor, dispatch])

    return (
        <IonPage className="welcome">
            <IonHeader>
                <div className="banner" style={{ backgroundColor: settings.themeColor }}>
                    <IonIcon icon={language} onClick={() => {
                        dispatch(
                            setLan({
                                isOpen: true
                            })
                        )
                    }} />
                </div>
            </IonHeader>
            <div className="content">
                <div className="more-options tr-button">
                    <span style={{ color: settings.themeColor, display: 'none' }} onClick={() => { 
                        setDemoURL('')
                        history.push('/signin/demo')
                     }}>
                        <Trans>
                            Sign in
                        </Trans>
                    </span>
                </div>
                <div className="logo">
                    <img src="https://image.cdn.pullpath.com.cn/pp-logo-sq-border.jpg" alt="" />
                </div>
                <div style={{ color: settings.themeColor }} className="text">
                    <Trans>
                        Lastly, confirm your demo URL.
                    </Trans>
                </div>
                <div className="inputField demourl">
                    <span>https://</span>
                    <input value={demoURL} placeholder={t("company")} onKeyUp={(evt) => {
                        evt.key === 'Enter' && continueWithDemoURL()
                    }} onChange={(evt) => {
                        setDemoURL(evt.target.value)
                    }}></input>
                    <span>.pullpathdemo.com</span>
                </div>
                <div className="button-section">
                    <button style={{ backgroundColor: settings.themeColor, borderColor: settings.themeColor, color: 'white' }} onClick={continueWithDemoURL}>
                        <Trans>
                            Continue
                        </Trans>
                    </button>
                </div>
                <div className="text note disabled">
                    <Trans>
                        This will be the URL of your demo site. We recommend using your company or brand name. This can be changed later and fully customized in our premium product levels.
                    </Trans>
                </div>
            </div>
        </IonPage>
    )
}

export default DemoURL
