import React, { useEffect, useState, useRef } from 'react'
import { IonHeader, IonPage, IonAlert, IonIcon } from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import { post } from '../utilities/API'
import StateDef from '../reducers/state.d'
import { useTranslation, Trans } from 'react-i18next'
import './DemoCommon.scss'
import { RouteComponentProps } from 'react-router'
import { fetchSettings } from '../reducers/settingsSlice'
import { set } from '../reducers/pullpathAlertSlice'
import { save as saveSignInEmail } from '../reducers/demoSignInSlice'
import Regex from '../utilities/Regex'
import { language } from 'ionicons/icons'
import { set as setLan } from '../reducers/languagePickerSlice'

const DemoSignIn: React.FC<RouteComponentProps> = ({ history, match }) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: StateDef) => state.settings)
    let [email, setEmail] = useState<string>('')
    let [showConfirm, setShowConfirm] = useState<boolean>(false)
    const signInEmailRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation()
    function signInWithEmail() {
        if (!Regex.email.test(email)) {
            dispatch(set({
                showAlert: true,
                header: t('Invalid email address'),
                message: t('Your email does not appear to a valid address. \r\nPlease check your typing, spelling and usage of @ symbols to make sure it is correct.')
            }))
            return
        }
        post('/demo/emailcheck', { email: email }).then(res => {
            let isExisted = res.payload.status === 'invalid'
            if (isExisted) {
                post('/demo/signin', { email: email }).then(res => {
                    // account not registered
                    if (res.payload.status === 'invalid') {
                        setShowConfirm(true)
                    }
                    // account registered, email has been sent
                    else if (res.payload.status === 'ok') {
                        dispatch(set({
                            showAlert: true,
                            header: t('Login email sent'),
                            message: t('We\'ve sent you an email with a link for you to login to your Demo account.\r\n\r\nClick the link in the email to continue to the demo site.')
                        }))
                    }
                })
            }
            else {
                // email not registered. confirm pop up.
                setShowConfirm(true)
            }
        })
    }

    function styleAlertBtn() {
        let btn: HTMLButtonElement | null = document.querySelector('.pullpath-confirm .alert-wrapper .alert-button-group button')
        let span = btn?.querySelector('span')
        if (btn?.style) {
            btn.style.setProperty('background-color', settings.themeColor)
            btn.style.setProperty('border-color', settings.themeColor)
            if (span?.style) {
                span.style.setProperty('color', 'white')
            }
        }
    }

    useEffect(() => {
        if (!settings.themeColor) {
            dispatch(fetchSettings())
        }
    }, [settings.themeColor, dispatch])

    return (
        <IonPage className="welcome">
            <IonHeader>
                <div className="banner" style={{ backgroundColor: settings.themeColor }}>
                    <IonIcon icon={language} onClick={() => {
                        dispatch(
                            setLan({
                                isOpen: true
                            })
                        )
                    }} />
                </div>
            </IonHeader>
            <div className="content">
                <div className="more-options tr-button">
                    <span style={{ color: settings.themeColor }} onClick={() => { 
                        setEmail('')
                        history.push('/signup/email') 
                    }}>
                        <Trans>
                            Create Site
                        </Trans>
                    </span>
                </div>
                <div className="logo">
                    <img src="https://image.cdn.pullpath.com.cn/pp-logo-sq-border.jpg" alt="" />
                </div>
                <div style={{ color: settings.themeColor }} className="text">
                    <Trans>
                        Sign in with your work email address.
                    </Trans>
                </div>
                <div className="inputField">
                    <input ref={signInEmailRef} value={email} placeholder={t("Email address")} onKeyUp={(evt) => {
                        evt.key === 'Enter' && signInWithEmail()
                    }} onChange={(evt) => {
                        setEmail(evt.target.value)
                    }}></input>
                </div>
                <div className="button-section">
                    <button style={{ backgroundColor: settings.themeColor, borderColor: settings.themeColor, color: 'white' }} onClick={signInWithEmail}>
                        <Trans>
                            Sign In
                        </Trans>
                    </button>
                </div>
            </div>
            <IonAlert
                onDidDismiss={() => { setShowConfirm(false) }}
                onWillPresent={styleAlertBtn}
                isOpen={showConfirm}
                cssClass='pullpath-confirm'
                header={t('We can\'t find your account')}
                message={t('We couldn\'t find an account matching ##. \r\n\r\nPlease try again or you can create a new account.').replace(/##/, email)}
                backdropDismiss={false}
                buttons={[
                    {
                        text: t('Create Account'),
                        handler: () => {
                            dispatch(saveSignInEmail({
                                email: email
                            }))
                            setEmail('')
                            history.push('/signup/email')
                        }
                    },
                    {
                        text: t('Try again'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                            console.log(blah)
                        }
                    }
                ]}
            />
        </IonPage>
    )
}

export default DemoSignIn
