import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            'en': {
                translations: {
                    "Sign in": "Sign in",
                    "Let's get started.": "Let's get started.",
                    "Create Site": "Create Site",
                    "We will create a new demo site so that you can try using Pullpath with your team. The demo site is free for small teams, up to 20 people.":
                        "We will create a new demo site so that you can try using Pullpath with your team. The demo site is free for small teams, up to 20 people.",
                    "By clicking Create Site, Continue, Create Account or More Options above, I agree to the Terms of this site. By going to the next step, I confirm that I am 18 years old or above.":
                        "By clicking Create Site, Continue, Create Account or More Options above, I agree to the Terms of this site. By going to the next step, I confirm that I am 18 years old or above.",
                    "Please wait...": "Please wait...",
                    "Demo account exists": "Demo account exists",
                    "It looks like you have already registered a demo account using <strong>##</strong>.\r\n\r\nWe have sent you an email with a link to login.": "It looks like you have already registered a demo account using <strong>##</strong>.\r\n\r\nWe have sent you an email with a link to login.",
                    "your email address": "your email address",
                    "What's your work email address?": "What's your work email address?",
                    "Continue": "Continue",
                    "We ask for your work email address so that your colleagues will recognize it when you invite them. We will send you a confirmation email to this address.": "We ask for your work email address so that your colleagues will recognize it when you invite them. We will send you a confirmation email to this address.",
                    "Your new password": "Your new password",
                    "Please create a password.": "Please create a password.",
                    "company": "company",
                    "Lastly, confirm your demo URL.": "Lastly, confirm your demo URL.",
                    "This will be the URL of your demo site. We recommend using your company or brand name. This can be changed later and fully customized in our premium product levels.": "This will be the URL of your demo site. We recommend using your company or brand name. This can be changed later and fully customized in our premium product levels.",
                    "Login email sent": "Login email sent",
                    "We\'ve sent you an email with a link for you to login to your Demo account.\r\n\r\nClick the link in the email to continue to the demo site.": "We\'ve sent you an email with a link for you to login to your Demo account.\r\n\r\nClick the link in the email to continue to the demo site.",
                    "Awaiting confirmation": "Awaiting confirmation",
                    "We\'ve sent you a confirmation email to ##. \r\n\r\nClick the link in the email to continue to the demo site.": "We\'ve sent you a confirmation email to ##. \r\n\r\nClick the link in the email to continue to the demo site.",
                    "Email address": "Email address",
                    "We can't find your account": "We can't find your account",
                    "Try again": "Try again",
                    "Sign in with your work email address.": "Sign in with your work email address.",
                    "Sign In": "Sign In",
                    "We couldn\'t find an account matching ##. \r\n\r\nPlease try again or you can create a new account.": "We couldn\'t find an account matching ##. \r\n\r\nPlease try again or you can create a new account.",
                    "Create Account": "Create Account",
                    "Invalid email address": "Invalid email address",
                    "Your email does not appear to a valid address. \r\nPlease check your typing, spelling and usage of @ symbols to make sure it is correct.": "Your email does not appear to a valid address. \r\nPlease check your typing, spelling and usage of @ symbols to make sure it is correct.",
                    'OK': 'OK',
                    "Invalid password": "Invalid password",
                    "Password should not be empty": "Password should not be empty",
                    "Please choose URL": "Please choose URL",
                    "To create your demo site, please type in your company or brand name in the box on the middle of the page before clicking the Continue button.": "To create your demo site, please type in your company or brand name in the box on the middle of the page before clicking the Continue button.",
                    "Please update site": "Please update site",
                    "Your demo site address can only contain letters and numbers 0-9, no other special characters or spaces. Please change it and try again.": "Your demo site address can only contain letters and numbers 0-9, no other special characters or spaces. Please change it and try again.",
                    "Cancel": "Cancel",
                    "Confirm": "Confirm",
                }
            },
            cn: {
                translations: {
                    "Sign in": "登录",
                    "Let's get started.": "让我们开始吧.",
                    "Create Site": "创建站点",
                    "We will create a new demo site so that you can try using Pullpath with your team. The demo site is free for small teams, up to 20 people.":
                        "我们将创建一个演示站点，然后您可以将Pullpath用于您的团队。演示站点对于小团队免费，最多20人上限。",
                    "By clicking Create Site, Continue, Create Account or More Options above, I agree to the Terms of this site. By going to the next step, I confirm that I am 18 years old or above.":
                        "通过点击创建站点，继续，创建账号或者上方的更多选项，我同意本站点的协议。在进入下一步前，我确认我年满18岁。",
                    "Please wait...": '请等待...',
                    "Demo account exists": "演示站点已存在",
                    "It looks like you have already registered a demo account using <strong>##</strong>.\r\n\r\nWe have sent you an email with a link to login.": "看起来您已经使用<strong>##</strong> 注册过示例账号。 \r\n\r\n我们已经给您的邮箱发送了链接，您可以使用它来登录。",
                    "your email address": "您的邮箱地址",
                    "What's your work email address?": "您的工作邮箱地址？",
                    "Continue": "继续",
                    "We ask for your work email address so that your colleagues will recognize it when you invite them. We will send you a confirmation email to this address.": "我们需要得到您的工作邮箱地址，这样您邀请了您的同事后他们可以立马识别出来。我们会给您的邮箱发送一封确认邮件。",
                    "Your new password": "您的新密码",
                    "Please create a password.": "请创建一个密码。",
                    "company": "公司",
                    "Lastly, confirm your demo URL.": "最后，请确认您的演示站点的访问地址。",
                    "This will be the URL of your demo site. We recommend using your company or brand name. This can be changed later and fully customized in our premium product levels.": "这将会是您演示站点的访问地址。我们推荐您使用您公司的品牌名称。这个可以随后更改，并且如果您是购买了旗舰版，所有都可以定制。",
                    "Login email sent": "登录邮件已发送",
                    "We\'ve sent you an email with a link for you to login to your Demo account.\r\n\r\nClick the link in the email to continue to the demo site.": "我们已经给您发送了一封包含登录到您演示站点的链接的邮件。\r\n\r\n点击邮件中的链接来继续访问演示站点。",
                    "Awaiting confirmation": "等待确认",
                    "We\'ve sent you a confirmation email to ##. \r\n\r\nClick the link in the email to continue to the demo site.": "我们已经给您的 ## 发送了一封确认邮件。 \r\n\r\n点击邮件中的链接来继续访问演示站点。",
                    "Email address": "邮箱地址",
                    "We can't find your account": "我们找不到您的账号",
                    "Try again": "再试一次",
                    "Sign in with your work email address.": "用您的工作邮件地址来登录",
                    "Sign In": "登录",
                    "We couldn\'t find an account matching ##. \r\n\r\nPlease try again or you can create a new account.": "我们找不到与 ## 相匹配的账号。 \r\n\r\n请再试一次或者创建一个新的账号。",
                    "Create Account": "创建账号",
                    "Invalid email address": "邮箱地址不合法",
                    "Your email does not appear to a valid address. \r\nPlease check your typing, spelling and usage of @ symbols to make sure it is correct.": "您的邮箱地址不是一个合法的地址。\r\n请检查您输入的内容，拼写，是否输入了@，确保邮箱地址正确。",
                    'OK': '好',
                    "Invalid password": "不合法的密码",
                    "Password should not be empty": "密码不能为空",
                    "Please choose URL": "请选择地址",
                    "To create your demo site, please type in your company or brand name in the box on the middle of the page before clicking the Continue button.": "为了创建演示站点，请在页面中间的输入框输入您的公司或者品牌名称，然后点击继续。",
                    "Please update site": "请更新站点",
                    "Your demo site address can only contain letters and numbers 0-9, no other special characters or spaces. Please change it and try again.": "您的演示站点地址只能包含字母和数字0-9，不允许其他字符或者空格。请更改地址再试一次。",
                    "Cancel": "取消",
                    "Confirm": "确认",
                }
            }
        },
        fallbackLng: "en",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    })

export default i18n
