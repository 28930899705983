import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  IonApp,
  IonRouterOutlet,
  IonLoading
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import Email from './pages/Email'
import Welcome from './pages/Welcome'
import Password from './pages/Password'
import DemoURL from './pages/DemoURL'
import DemoSignIn from './pages/DemoSignIn'
import PullpathAlert from './components/PullpathAlert'
import PullpathError from './components/PullpathError'
import LanguagePicker from './components/LanguagePicker'
import { set } from './reducers/languagePickerSlice'
import { useSelector, useDispatch } from 'react-redux'
import StateDef from './reducers/state.d'
import { useTranslation } from 'react-i18next'
import i18n from './utilities/i18n'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/global.scss'

const App: React.FC = () => {
  const loading = useSelector((state: StateDef) => state.loading)
  const languagePicker = useSelector((state: StateDef) => state.languagePicker)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet mode="md">
          <Route path="/welcome" component={Welcome} exact={true} />
          <Route path="/signup/email" component={Email} />
          <Route path="/signup/password" component={Password} />
          <Route path="/signup/demourl" component={DemoURL} />
          <Route path="/signin/demo" component={DemoSignIn} />
          <Route path="/" render={() => <Redirect to="/welcome" />} exact={true} />
        </IonRouterOutlet>
        <IonLoading
          cssClass='pullpath-loading'
          isOpen={loading.isLoading}
          // onDidDismiss={() => setShowLoading(false)}
          message={t('Please wait...')}
        // duration={5000}
        />
        <PullpathAlert />
        <PullpathError />
        <LanguagePicker isOpen={languagePicker.isOpen} onSave={(val: any) => {
          dispatch(set({
            currentLanguage: val.language.value,
            isOpen: false
          }))
          i18n.changeLanguage(val.language.value)
        }} onCancel={() => {
          dispatch(set({
            isOpen: false
          }))
        }} />
      </IonReactRouter>
    </IonApp>
  )
}

export default App
