import { createSlice } from '@reduxjs/toolkit'
import i18n from '../utilities/i18n'

const slice = createSlice({
    name: 'pullpathError',
    initialState: {
        showError: false,
        cssClass: 'pullpath-error',
        header: '',
        subHeader: '',
        message: '',
        buttonText: i18n.t('OK'),
        backdropDismiss: false
    },
    reducers: {
        set: (state, action) => {
            return {...state, ...action.payload}
        }
    }
})

export default slice.reducer
export const { set } = slice.actions