import React from "react"
import { PickerColumn } from "@ionic/core"
import { IonPicker } from "@ionic/react"
import { useTranslation } from 'react-i18next'
import './LanguagePicker.scss'

/**
 * the component has three properties that are defined in this 
 * interface since we are using typescript
 */
interface _Props {
    isOpen: boolean
    onSave: Function
    onCancel: Function
}

const LanguagePicker: React.FC<_Props> = ({ onSave, onCancel, isOpen }) => {
    const { t } = useTranslation()
    const LanguageList = {
        name: "language",
        options: [
            { text: "English", value: "en" },
            { text: "中文", value: "cn" },
        ]
    } as PickerColumn

    return (
        <div>
            <IonPicker
                isOpen={isOpen}
                columns={[LanguageList]}
                buttons={[
                    {
                        text: t('Cancel'),
                        role: "cancel",
                        handler: value => {
                            onCancel()
                        }
                    },
                    {
                        text: t('Confirm'),
                        handler: value => {
                            onSave(value)
                        }
                    }
                ]}
            ></IonPicker>
        </div>
    )
}

export default LanguagePicker