import React from 'react'
import { IonAlert } from '@ionic/react'
import { useSelector } from 'react-redux'
import { set } from '../reducers/pullpathAlertSlice'
import {useDispatch} from 'react-redux'
import StateDef from '../reducers/state.d'

const PullpathAlert: React.FC = () => {
    const pullpathAlert = useSelector((state: StateDef) => state.pullpathAlert)
    const settings = useSelector((state: StateDef) => state.settings)
    const dispatch = useDispatch()

    function styleAlertBtn() {
        let btn: HTMLButtonElement | null = document.querySelector('.pullpath-alert .alert-wrapper .alert-button-group button')
        let span = btn?.querySelector('span')
        if (btn?.style) {
            btn.style.setProperty('background-color', settings.themeColor)
            btn.style.setProperty('border-color', settings.themeColor)
            if (span?.style) {
                span.style.setProperty('color', 'white')
            }
        }
    }
    return <IonAlert
        onDidDismiss={() => { 
            dispatch(set({showAlert: false}))
        }}
        onWillPresent={styleAlertBtn}
        isOpen={pullpathAlert.showAlert}
        cssClass={pullpathAlert.cssClass}
        header={pullpathAlert.header}
        subHeader={pullpathAlert.subHeader || ''}
        message={pullpathAlert.message}
        buttons={[
            {
                text: pullpathAlert.buttonText,
                // cssClass: 'secondary',
                handler: () => {

                }
            },
        ]}
        backdropDismiss={false}
    />
}

export default PullpathAlert