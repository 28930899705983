import axios from 'axios'
import store from '../store/index'
import { toggleLoadingStatus } from '../reducers/loadingSlice'
import { set } from '../reducers/pullpathErrorSlice'

const hostName: string = window.location.hostname
let API_SERVER: string = (
    hostName === 'localhost' || hostName === 'local.pullpath.me'
    || hostName === 'local.pullpath.com' || hostName === 'wechat.pullpath.com.cn'
    || /(\d+.)+\d+$/.test(hostName)
)
    ? 'http://welcome-admin-dev.pullpath.com/APIEntry.php'
    : 'http://' + hostName.replace(/^(\w+)/, '$1-admin') + '/APIEntry.php'

API_SERVER = 'https://saas.pullpath.com.cn/APIEntry.php'

// const clientType = window.location.hostname.split('.')[0]
const clientType = 'welcome-dev' // for test, change it to the one above later.

const serializeData = (data: { [index: string]: number | string | boolean | undefined }): string => {
    let buffer = [];
    // Serialize each key in the object.
    for (let name in data) {
        if (!data.hasOwnProperty(name)) {
            continue
        }
        let value = data[name]
        if (value === null || value === undefined) {
            buffer.push(
                encodeURIComponent(name) +
                '=' +
                encodeURIComponent('')
            )
        }
        else {
            buffer.push(
                encodeURIComponent(name) +
                '=' +
                encodeURIComponent((value === null) ? '' : value)
            )
        }
    }
    // Serialize the buffer and clean it up for transportation.
    let source = buffer
        .join('&')
        .replace(/%20/g, '+');
    return (source)
}

export const post = (path: string, payload: {}) => {
    const dispatch = store.dispatch
    if (!store.getState().loading.isLoading) {
        dispatch(toggleLoadingStatus(true))
    }
    return axios.post(API_SERVER, serializeData({
        path: path,
        payload: JSON.stringify(payload),
        clientType: clientType,
        token: undefined
    }), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
    }).then(res => {
        dispatch(toggleLoadingStatus(false))
        return res.data
    }).catch(err => {
        dispatch(toggleLoadingStatus(false))
        if (err.response) {
            // client received an error response (5xx, 4xx)
            dispatch(set({
                showError: true,
                header: err.response?.data?.status,
                message: err.response?.data?.errorMessage
            }))
            return err.response.data
        } else if (err.request) {
            // client never received a response, or request never left
            console.error(err.request)
        } else {
            // anything else
            console.error(err)
        }
    })
}