import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'loading',
    initialState: {
        isLoading: false
    },
    reducers: {
        toggleLoadingStatus: (state, action) => {
            return {...state, isLoading: action.payload}
        }
    }
})

export default slice.reducer
export const { toggleLoadingStatus } = slice.actions