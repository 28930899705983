import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'languagePicker',
    initialState: {
        isOpen: false,
        currentLanguage: localStorage.getItem('i18nextLng') || 'en'
    },
    reducers: {
        set: (state, action) => {
            return {...state, ...action.payload}
        },
        get: (state, action) => {
            return {...state}
        }
    }
})

export default slice.reducer
export const { set, get } = slice.actions