import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../utilities/API'

export const fetchSettings = createAsyncThunk('settings/fetchSettings', async () => {
    const res = await post('/login/login', {})
    return res.payload.settings
})

const slice = createSlice({
    name: 'settings',
    initialState: {
    },
    reducers: {
        applySettings: (state, action) => {
            return {...state, ...action.payload}
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            return  {...state, ...action.payload}
        })
    }
})

export default slice.reducer
export const { applySettings } = slice.actions