import React from 'react'
import { IonAlert } from '@ionic/react'
import { useSelector } from 'react-redux'
import { set } from '../reducers/pullpathErrorSlice'
import {useDispatch} from 'react-redux'
import StateDef from '../reducers/state.d'

const PullpathError: React.FC = () => {
    const pullpathError = useSelector((state: StateDef) => state.pullpathError)
    const dispatch = useDispatch()

    function styleAlertBtn() {
        let btn: HTMLButtonElement | null = document.querySelector('.pullpath-error .alert-wrapper .alert-button-group button')
        let span = btn?.querySelector('span')
        if (btn?.style) {
            btn.style.setProperty('background-color', '#eb445a')
            btn.style.setProperty('border-color', '#eb445a')
            if (span?.style) {
                span.style.setProperty('color', 'white')
            }
        }
    }
    return <IonAlert
        onDidDismiss={() => { 
            dispatch(set({showAlert: false}))
        }}
        onWillPresent={styleAlertBtn}
        isOpen={pullpathError.showError}
        cssClass={pullpathError.cssClass}
        header={pullpathError.header}
        subHeader={pullpathError.subHeader || ''}
        message={pullpathError.message}
        buttons={[
            {
                text: pullpathError.buttonText,
                // cssClass: 'secondary',
                handler: () => {

                }
            },
        ]}
        backdropDismiss={false}
    />
}

export default PullpathError